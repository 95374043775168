import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./index.css";
// import App from "./App";
// import AppVers2 from "./AppVers2";
import AppVers3 from "./AppVers3";
import Careers from "./Careers";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      {/* <App /> */}
      {/* <AppVers2 /> */}
      <Switch>
        <Route exact path="/careers">
          <Careers />
        </Route>
        <Route exact path="/">
          <AppVers3 />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
