import React from "react";
// import { Link } from "react-router-dom";
import "./Landing.css";
import Form from "./Form";
import arrow from "./Arrow.svg";
// import logo from "./star5.svg";

const AppVers3 = () => {
  return (
    <>
      <div className="main-container">
        <header className="main-header">
          <div className="header-container">
            <a className="logo-section" href="/">
              {/* <img src={logo} alt="logo" /> */}
              <span className="logo-text">Starmentors</span>
            </a>
          </div>
        </header>
      </div>
      <div className="main-section">
        <div className="main-container">
          <h1 className="main-tagline">
            {/* Assembling remote teams <br />
            and rising digital talents */}
            Rising digital talents
          </h1>
        </div>
      </div>
      {/** Section for the about teaser */}
      <div className="main-container">
        <div className="about-teaser-section">
          <h2 className="about-teaser-headline">
            We support organizations <br />
            in building better world
          </h2>
          <p className="about-teaser-description">
            Our years-forged technical and managing experience and uniquely
            integrated approach power us to qualify and gather up resources that
            best fit our partners business demands.
            {/* We apply our years-forged experience and uniquely integrated
            approach to qualify and gather up resources that best fit our
            partners business demands. */}
          </p>
        </div>
        <div className="service-section">
          <div className="service-points-area">
            <h3 className="service-point-tagline">
              Audit of technical requirements &amp; processes
            </h3>
            <p className="service-point-description">
              Learning processes and aligning them with business goals, we
              identify problems and invent solutions equaling the current
              situation with business objectives.
            </p>
          </div>
          <div className="service-points-area">
            <h3 className="service-point-tagline">
              Targeted talent acquisition
            </h3>
            <p className="service-point-description">
              Putting in use our vast pool of talents we choose the future
              leaders to help our parnters achive ambitious goals.
            </p>
          </div>
          <div className="service-points-area">
            <h3 className="service-point-tagline">Onboarding consultation</h3>
            <p className="service-point-description">
              Selecting and developing individual systematic programs that help
              employees to adapt to new technological conditions, increase
              productivity, and focus on success.
            </p>
          </div>
          <div className="service-points-area">
            <h3 className="service-point-tagline">Staff augmentation</h3>
            <p className="service-point-description">
              Conducting a comprehensive assessment of existing staff and
              building teams of matched professionals to cover the missing
              skills.
            </p>
          </div>
        </div>
        <div className="career-teaser-section">
          <h3 className="career-teaser-tagline">Become One of Us</h3>
          <div className="career-link-container">
            <a className="career-teaser-link" href="/careers">
              See the list of currently open positions
            </a>
            <img className="arrow-link" src={arrow} alt="link" />
          </div>
        </div>
        <div className="contact-section">
          <h3 className="contact-tagline">Contact Us</h3>
          <p className="contact-description">We would be happy to help</p>
          {/* <span className="contact-link">FIll in the request form</span> */}
          <Form />
        </div>
        <footer className="footer-section">
          <p className="footer-label">
            © 2021 Starmentors, Inc. All rights reserved
          </p>
        </footer>
      </div>
    </>
  );
};

export default AppVers3;
