import React from "react";
import "./Careers.css";

const Careers = () => {
  return (
    <>
      <div className="main-careers-container">
        <div className="career-container-content-wrapper">
          <header className="main-header">
            <div className="header-container">
              <a className="logo-section" href="/">
                {/* <img src={logo} alt="logo" /> */}
                <span className="logo-text">Starmentors</span>
              </a>
            </div>
          </header>
          <div>
            <div className="subheader-careers">
              <div className="subheader-careers-content-wraper">
                <h2 className="careers-headline">Careers</h2>
                <p className="careers-description">
                  Our years-forged technical and managing experience and
                  uniquely integrated approach power us to qualify and gather up
                  resources that best fit our partners business demands
                </p>
              </div>
            </div>
          </div>
          <div className="section-positions">
            <h3 className="position-title">Computer Programmer</h3>
            <h3 className="position-title">Business Developer</h3>
            <h3 className="position-title">Project Manager</h3>
            <h3 className="position-title">Computer Programmer</h3>
            <h3 className="position-title">Business Developer</h3>
            <h3 className="position-title">Project Manager</h3>
            <h3 className="position-title">Computer Programmer</h3>
            <h3 className="position-title">Business Developer</h3>
            <h3 className="position-title">Project Manager</h3>
          </div>
        </div>
        <footer className="footer-section">
          <p className="footer-label">
            © 2021 Starmentors, Inc. All rights reserved
          </p>
        </footer>
      </div>
    </>
  );
};

export default Careers;
